/* global CarComponentCompacted,  */
class CarComponentOfidirect extends  CarComponentCompacted {

    checkStepConditions(){
        let res = super.checkStepConditions();
        if(res && this.currentStep === 2) {
            if(!this.$store.state.order_ReceptionManagerCode) {
                this.$store.dispatch('showNotificacion',{title:this.tr('Reception Manager'), message: "No puede quedar en blanco",type: 'info'});
                return false;
            }
        }
        return res;
    }

    getMethods() {
        let parentMethods = super.getMethods();
        parentMethods.checkStepConditions = this.checkStepConditions;
        return parentMethods;
    }
}

CarComponentOfidirect.registerComponent();
